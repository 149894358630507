import React, { useMemo } from 'react';
import CreatableSelect from 'react-select/async-creatable';
import FormGroup from '../Form/FormGroup';
import createRandomString from '../../functions/createRandomString';
import Label from '../Form/Label';
import InputLoader from '../Form/InputLoader';
import { defaultSelectStyles } from './defaultSelectStyles';
import ReactSelectRequiredInput from './ReactSelectRequiredInput';

type MultiSelectUserCreateProps = {
  id: string;
  onChange: (values: any[]) => any;
  defaultOptions?: { label: string; value: any }[];
  fullWidth?: boolean;
  testId?: string;
  isClearable?: boolean;
  label?: string | React.ReactNode;
  loading?: boolean;
  required?: boolean;
  selectedValues?: any[];
  emptyMessage?: string;
  placeholder?: string;
  selectOverflow?: boolean;
};

export default function MultiSelectUserCreate({
  onChange,
  defaultOptions = [],
  fullWidth = false,
  isClearable = false,
  label = 'Add options',
  loading = false,
  required = false,
  selectedValues = [],
  testId = '',
  emptyMessage = 'Type to create',
  id,
  selectOverflow = false,
  placeholder = 'Search...',
}: MultiSelectUserCreateProps) {
  const inputId = useMemo(() => id || `multiSelectIdSelect${createRandomString(5)}`, [id]);

  if (loading) return <InputLoader />;

  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    const filteredOptions = defaultOptions.filter((option) => option.label.toLowerCase().includes(inputValue?.toLowerCase()));
    callback(filteredOptions);
  };

  return (
    <FormGroup fullWidth={fullWidth} data-testid={testId}>
      <Label id={inputId} label={label} required={required} />
      <div className="relative">
        <CreatableSelect
          defaultOptions={defaultOptions}
          isClearable={isClearable}
          isMulti
          inputId={inputId}
          classNamePrefix="equips"
          placeholder={placeholder}
          value={
            (selectedValues &&
              selectedValues.map((value) => {
                const label = defaultOptions?.find((option) => option.value === value)?.label;

                return { label: label || value, value };
              })) ||
            []
          }
          loadOptions={loadOptions}
          onChange={(values) => onChange(values ? values.map(({ value }) => value) : [])}
          noOptionsMessage={() => emptyMessage}
          styles={defaultSelectStyles}
          // https://stackoverflow.com/a/63898890
          {...(selectOverflow && { menuPortalTarget: document.querySelector('body') })}
        />
        {required && <ReactSelectRequiredInput isValid={!!selectedValues} />}
      </div>
    </FormGroup>
  );
}

import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import {
  devRoutes,
  defaultRoutes,
  unauthenticatedRoutes,
  uninitializedUserRoutes,
  authenticatedRoutes,
  unauthenticatedOrAuthenticatedRoutes,
} from '../router';
import { useAuth } from '../common/auth/AuthContext';
import Urls from './Urls';

const UninitializedUserRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const authenticatedAndInitialized = auth && auth.initialized;
      const authenticatedButNotInitialized = auth && !auth.initialized;

      if (authenticatedButNotInitialized) {
        return <Component {...props} uninitialized auth={auth} />;
      } else if (authenticatedAndInitialized) {
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      }

      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }}
  />
);

const UnauthenticatedOrAuthenticatedRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} auth={auth} />;
      }}
    />
  );
};

export const AuthenticatedRoute = ({ component: Component, auth, ...rest }) => {
  const { isActive } = useAuth();
  const authenticatedAndInitialized = auth && auth.initialized;
  const authenticatedButNotInitialized = auth && !auth.initialized;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isActive && authenticatedAndInitialized) {
          return <Component {...props} auth={auth} routes={rest.routes} />;
        } else if (authenticatedButNotInitialized) {
          return (
            <Redirect
              to={{
                pathname: `${Urls.ONBOARDING}`,
                state: { from: props.location },
              }}
            />
          );
        } else if (!isActive && auth) {
          return <Redirect to={{ pathname: `${Urls.INACTIVE}`, state: { from: props.location } }} />;
        }

        return <Redirect to={{ pathname: `${Urls.LOGIN}`, state: { from: props.location } }} />;
      }}
    />
  );
};

const UnauthenticatedRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const unauthenticated = !auth;

      if (unauthenticated) {
        return <Component {...props} auth={auth} />;
      }

      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }}
  />
);

const DefaultRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return <Component {...props} auth={auth} />;
    }}
  />
);

function MainRouter({ location }) {
  const { auth } = useAuth();

  return (
    <section id="content" data-testid="route-section" className="route-section" style={{ minHeight: 'calc(100vh - 111px)' }}>
      <Switch location={location}>
        {unauthenticatedOrAuthenticatedRoutes.map((route) => (
          <UnauthenticatedOrAuthenticatedRoute key={route.path} path={route.path} exact component={route.component} auth={auth} />
        ))}
        {authenticatedRoutes.map((route, index) => (
          <AuthenticatedRoute
            key={index}
            {...route}
            path={route.path}
            exact={!['/tasks', '/aas/tasks', '/capture/verify'].some((path) => path === route.path)}
            component={route.component}
            auth={auth}
          />
        ))}
        {uninitializedUserRoutes.map((route) => (
          <UninitializedUserRoute key={route.path} path={route.path} exact component={route.component} auth={auth} />
        ))}
        {unauthenticatedRoutes.map((route) => (
          <UnauthenticatedRoute key={route.path} path={route.path} exact component={route.component} auth={auth} />
        ))}
        {window.process.env.stage === 'dev' &&
          devRoutes.map((route) => <AuthenticatedRoute key={route.path} path={route.path} exact component={route.component} auth={auth} />)}
        {defaultRoutes.map((route) => (
          <DefaultRoute key={route.path} path={route.path} exact component={route.component} auth={auth} />
        ))}
      </Switch>
    </section>
  );
}

export default withRouter(MainRouter);

import React, { useContext } from 'react';
import {
  ClientOrganizationEnumType,
  OrganizationBillingMethodType,
  OrganizationFeatureFlagEnum,
  ServiceTypeEnum,
  UserAuthorizationRoleEnum,
  UserTagsType,
} from '@equips/entities-schema';
import { useTranslation } from 'react-i18next';
import Urls from '../../../routes/Urls';
import SubmitRequest from '../ServiceRequest/SubmitRequest';
import { ModalContext } from '../../providers/ModalProvider';
import { admins, nonProviders } from '../../auth/roles';
import { AuthContextValue } from '../../auth/AuthContext';
import { useFliptBoolean } from '../../providers/flipt/useFliptClient';
import { CAPTURE_URLS } from '../../../screens/qr-codes/libs/captureRoutes';
import { AAS_TASKS_URLS } from '../../../screens/aas/tasks/libs/aasTasksRoutes';

type MenuItemType = { content: string; nested: { to?: string; content: string; onClick?: () => any }[] };

const useNavItems = (authData: AuthContextValue): MenuItemType[] => {
  const {
    auth,
    userCan,
    internalUsers,
    permissions,
    roles,
    clientOrganizationType,
    determineFeatureFlagVisibility,
    organizationBillingMethod,
    isAasUser,
  } = authData;

  const { showModal } = useContext(ModalContext);

  const { t } = useTranslation();

  const canCapture = useFliptBoolean('captures', false, auth?.userId || '');

  const aasClaimNotesQueue = useFliptBoolean('RD-493-armis-eliminate-estimate-inbox-task-view', false, auth?.userId || '');

  const navItems = {
    service: {
      viewRequests: { to: Urls.SERVICE_REQUESTS, content: t('viewWithNoun', { noun: t('serviceRequests') }) },
      viewRecurringPMs: {
        to: `${Urls.SERVICE_REQUESTS}?metadata.recurrenceInterval=all&metadata.requestStatus=%5B"internalDispatch"%2C"equipsDispatch"%2C"providerDispatch"%5D&metadata.serviceType=%5B"Preventive"%5D`,
        content: t('recurringPMs'),
      },
      submitServiceRequest: {
        onClick: () => showModal(() => <SubmitRequest serviceType={ServiceTypeEnum.Corrective} />),
        content: t('submitServiceRequest'),
      },
      schedulePreventiveMaintenance: {
        onClick: () => showModal(() => <SubmitRequest serviceType={ServiceTypeEnum.Preventive} />),
        content: t('schedulePreventiveMaintenance'),
      },
      orderConsumable: {
        onClick: () => showModal(() => <SubmitRequest serviceType={ServiceTypeEnum.Consumables} />),
        content: t('orderConsumables'),
      },
      dispatch: { to: Urls.SERVICE_REQUEST_DISPATCH, content: t('dispatch') },
      schedule: { to: Urls.SCHEDULE, content: t('schedule') },
      squads: { to: Urls.SQUADS, content: t('squads') },
      serviceProviderView: { to: Urls.SERVICE_PROVIDER_VIEW, content: 'Service Provider View' },
    },
    equipment: {
      viewEquipment: { to: Urls.EQUIPMENT, content: t('viewWithNoun', { noun: t('equipment') }) },
      addEquipment: { to: Urls.EQUIPMENT_CREATE, content: t('addWithNoun', { noun: t('equipment') }) },
      viewChronicIssues: { to: `${Urls.EQUIPMENT}?metadata.hasChronicIssues=true`, content: t('chronicIssues') },
      generateQRCodes: { to: Urls.EQUIPMENT_QR, content: t('qrCodes') },
      pmSchedule: { to: Urls.EQUIPMENT_PM, content: t('pmSchedule') },
      capture: { to: CAPTURE_URLS.ROOT, content: t('capture') },
    },
    admin: {
      locations: { to: Urls.LOCATIONS, content: t('locations') },
      users: { to: Urls.USERS, content: t('users') },
      agreements: { to: Urls.AGREEMENTS, content: t('agreements') },
      serviceContracts: { to: Urls.SERVICE_CONTRACTS, content: t('serviceContracts') },
      customerInvoices: { to: Urls.CUSTOMER_INVOICES, content: t('serviceInvoices') }, // payment screen
      providerInvoices: { to: Urls.PROVIDER_INVOICES, content: t('providerInvoices') }, // legacy payment screen
      documents: { to: Urls.DOCUMENTS, content: t('documents') },
      ihtInvoices: { to: Urls.IHT_INVOICES, content: t('inHouseTech') },
      import: { to: Urls.IMPORT, content: t('import') },
      nonEquipsInvoices: { to: Urls.AAS_INVOICES, content: t('invoices') }, // invoice migration
      inventory: { to: Urls.INVENTORY, content: t('inventory') },
    },
    aas: {
      contracts: { to: Urls.AAS_CONTRACTS, content: t('contracts') },
      contractsData: { to: Urls.AAS_CONTRACTS_DATA, content: t('contractsData') },
      claims: { to: Urls.AAS_CLAIMS, content: t('claims') },
      claimForm: { to: Urls.AAS_FORM, content: t('claimForm') },
      import: { to: Urls.AAS_IMPORT, content: t('import') },
      invoices: { to: Urls.AAS_INVOICES, content: t('invoices') },
      payables: { to: Urls.AAS_PAYABLES, content: t('payables') },
      providers: { to: Urls.AAS_ORGANIZATIONS, content: t('providers') },
      tasks: { to: AAS_TASKS_URLS.ROOT, content: 'Claim Notes' },
    },
    homeWarranty: {
      contracts: { to: Urls.SERVICE_CONTRACTS, content: t('contracts') },
      claims: { to: Urls.CLAIMS, content: t('claims') },
      invoices: { to: Urls.AAS_INVOICES, content: t('invoices') },
      payables: { to: Urls.AAS_PAYABLES, content: t('payables') },
      providers: { to: Urls.HOME_WARRANTY_ORGANIZATIONS, content: t('providers') },
    },
    equips: {
      serviceInvoices: { to: Urls.INVOICES, content: t('serviceInvoices') }, // invoices screen
      providerInvoices: { to: Urls.PROVIDER_INVOICES, content: t('providerInvoices') }, // legacy payment screen
      coverages: { to: Urls.COVERAGE, content: t('coverages') },
      charges: { to: Urls.CHARGES, content: t('charges') },
      subscriptions: { to: Urls.SUBSCRIPTIONS, content: t('subscriptions') },
      incidents: { to: Urls.INCIDENTS, content: t('incidents') },
      organizations: { to: Urls.ORGANIZATIONS, content: t('organizations') },
      categories: { to: Urls.CATEGORIES, content: t('categories') },
      specs: { to: Urls.SPECS, content: t('specs') },
      reporting: { to: Urls.REPORTING, content: t('reports') },
      notifications: { to: Urls.NOTIFICATIONS, content: t('notifications') },
      feedback: { to: Urls.FEEDBACK, content: t('feedback') },
      tests: { to: Urls.TESTS, content: t('tests') },
      pricingTool: { to: Urls.COVERAGE_PRICING_TOOL, content: t('pricingTool') },
    },
  };

  const buildLeft = () => {
    const leftItems: MenuItemType[] = [];

    if (!auth || (auth && !auth.initialized) || userCan([UserAuthorizationRoleEnum.ProviderEmployee])) {
      return leftItems;
    }

    const addMenuItem = (content, nested) => {
      leftItems.push({
        content,
        nested,
      });
    };

    if (isAasUser) {
      addMenuItem(t('claimForm'), [navItems.aas.claimForm]);
      addMenuItem(t('claims'), [navItems.aas.claims]);
      addMenuItem(t('contracts'), [navItems.aas.contracts]);

      if (determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceVisibility)) {
        addMenuItem(t('invoices'), [navItems.aas.invoices]);
      }

      addMenuItem(t('admin'), [
        ...(userCan([UserAuthorizationRoleEnum.CustomerLocationAdmin])
          ? [navItems.admin.locations]
          : [
              navItems.admin.locations,
              navItems.aas.contractsData,
              navItems.aas.providers,
              ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Squad) ? [navItems.service.squads] : []),
              navItems.aas.payables,
              navItems.aas.import,
              ...(aasClaimNotesQueue ? [navItems.aas.tasks] : []),
            ]),
        ...(auth?.tags?.includes(UserTagsType.AasAdmin) || auth.tags?.includes(UserTagsType.AasUserAdministrator)
          ? [navItems.admin.users]
          : []),
      ]);
      return leftItems;
    }

    if (clientOrganizationType === ClientOrganizationEnumType.EquipsHomeWarranty) {
      addMenuItem(t('claims'), [navItems.homeWarranty.claims]);
      addMenuItem(t('contracts'), [navItems.homeWarranty.contracts]);

      if (determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceVisibility)) {
        addMenuItem(t('invoices'), [navItems.homeWarranty.invoices]);
      }

      addMenuItem(t('admin'), [
        ...(userCan([UserAuthorizationRoleEnum.CustomerLocationAdmin]) ? [navItems.admin.locations] : []),
        ...(userCan([UserAuthorizationRoleEnum.CustomerOrganizationAdmin])
          ? [navItems.admin.locations, navItems.homeWarranty.providers, navItems.homeWarranty.payables, navItems.admin.import]
          : []),
        ...(userCan(internalUsers) ? [navItems.admin.users] : []),
      ]);
      return leftItems;
    }

    if (userCan(internalUsers)) {
      addMenuItem(t('service'), [
        navItems.service.viewRequests,
        ...(permissions.isCoverageInternalUser || permissions.isWOMInternalUser
          ? [
              navItems.service.viewRecurringPMs,
              navItems.service.submitServiceRequest,
              navItems.service.schedulePreventiveMaintenance,
              navItems.service.orderConsumable,
              ...(permissions.isCoverageInternalUser ? [navItems.service.dispatch, navItems.service.serviceProviderView] : []),
              navItems.service.schedule,
              ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Squad) ? [navItems.service.squads] : []),
            ]
          : []),
      ]);

      if (
        (permissions.isCoverageInternalUser || permissions.isWOMInternalUser) &&
        determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility)
      ) {
        addMenuItem(t('equipment'), Object.values(navItems.equipment));
      }

      addMenuItem(t('admin'), [
        navItems.admin.locations,
        navItems.admin.users,
        ...(userCan(internalUsers) ? [navItems.admin.serviceContracts] : []),
        ...(permissions.isCoverageInternalUser ? [navItems.admin.agreements, navItems.admin.customerInvoices] : []),
        // invoice migration
        ...(permissions.isWOMInternalUser && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceVisibility)
          ? [navItems.admin.nonEquipsInvoices]
          : []),
        navItems.admin.documents,
        ...(permissions.userCanViewInHouseTechForm ? [navItems.admin.ihtInvoices] : []),
        navItems.admin.import,
        ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Parts) ? [navItems.admin.inventory] : []),
      ]);

      if ((userCan([UserAuthorizationRoleEnum.SuperGlobalAdmin]) && permissions.isAASInternalUser) || isAasUser) {
        addMenuItem(t('aas'), Object.values(navItems.aas));
      }

      addMenuItem(t('equips'), [
        ...(permissions.isCoverageInternalUser
          ? [
              navItems.equips.serviceInvoices,
              navItems.equips.providerInvoices,
              navItems.equips.coverages,
              navItems.equips.charges,
              navItems.equips.subscriptions,
              navItems.equips.incidents,
              navItems.equips.pricingTool,
            ]
          : []),
        // not using !permissions.isAASInternalUser to make it work for userTags = [] (assume allow all)
        ...(permissions.isCoverageInternalUser || permissions.isWOMInternalUser
          ? [navItems.equips.organizations, navItems.equips.categories, navItems.equips.specs, navItems.equips.reporting]
          : []),
        navItems.equips.notifications,
        navItems.equips.feedback,
        ...(userCan([UserAuthorizationRoleEnum.SuperGlobalAdmin, UserAuthorizationRoleEnum.GlobalAdmin]) ? [navItems.equips.tests] : []),
      ]);
    } else {
      const canSubmitServiceRequest = userCan(nonProviders);
      const canSchedulePreventiveMaintenance =
        userCan(nonProviders) && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.DashboardPreventativeMaintenance);
      const canOrderConsumable = userCan(nonProviders) && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.DashboardConsumables);
      const canPmSchedule =
        userCan(admins) && userCan(nonProviders) && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.PmScheduleAdvanced);

      addMenuItem(t('service'), [
        navItems.service.viewRequests,
        navItems.service.viewRecurringPMs,
        ...(canSubmitServiceRequest ? [navItems.service.submitServiceRequest] : []),
        ...(canSchedulePreventiveMaintenance ? [navItems.service.schedulePreventiveMaintenance] : []),
        ...(canOrderConsumable ? [navItems.service.orderConsumable] : []),
        ...(clientOrganizationType === ClientOrganizationEnumType.EquipsWorkOrderManagement ? [navItems.service.schedule] : []),
      ]);

      if (determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility)) {
        addMenuItem(t('equipment'), [
          navItems.equipment.viewEquipment,
          ...(userCan(admins) && userCan(nonProviders) ? [navItems.equipment.addEquipment] : []),
          ...(userCan(nonProviders) ? [navItems.equipment.viewChronicIssues] : []),
          ...(userCan(admins) && userCan(nonProviders) ? [navItems.equipment.generateQRCodes] : []),
          ...(canPmSchedule ? [navItems.equipment.pmSchedule] : []),
          ...(canCapture ? [navItems.equipment.capture] : []),
        ]);
      }

      if (userCan(admins)) {
        addMenuItem(t('admin'), [
          navItems.admin.locations,
          navItems.admin.users,
          ...(userCan(nonProviders) ? [navItems.admin.agreements] : []),
          ...(clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage &&
          organizationBillingMethod === OrganizationBillingMethodType.SingleSourceBilling
            ? [navItems.admin.customerInvoices]
            : []),
          // invoice migration
          ...(permissions.isWOMInternalUser && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceVisibility)
            ? [navItems.admin.nonEquipsInvoices]
            : []),
          ...(clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage &&
          organizationBillingMethod === OrganizationBillingMethodType.ExternalBilling
            ? [navItems.admin.providerInvoices]
            : []),
          navItems.admin.documents,
          ...(permissions.userCanViewInHouseTechForm ? [navItems.admin.ihtInvoices] : []),
          navItems.admin.import,
          ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Parts) ? [navItems.admin.inventory] : []),
          ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Squad) ? [navItems.service.squads] : []),
        ]);
      }

      if (auth.authorizationRole === roles.customerLocationAdmin) {
        addMenuItem(t('admin'), [navItems.admin.locations]);
      }
    }
    return leftItems;
  };

  return buildLeft();
};

export default useNavItems;

import React from 'react';
import { Link } from 'react-router-dom';
import {
  ClientOrganizationEnumType,
  EntityLabelEnum,
  OrganizationFeatureFlagEnum,
  patchServiceRequest,
  ServiceRequest,
} from '@equips/entities-schema';
import { JsonParam } from 'use-query-params';
import { Translation } from 'react-i18next';
import Urls from '../../routes/Urls';
import Pill from '../../common/components/Pills/Pill';
import { enumObjectToSelectOptions, EnumObjectType } from '../../graphql/enums';
import { AuthContextValue, AuthorizationData } from '../../common/auth/AuthContext';
import { TableColumn } from '../../common/components/DataTable';
import { customers, internalUsers, providers } from '../../common/auth/roles';
import { defaultColumnWidths } from '../../common/components/DataTable/columns';
import TableCellInlineEditable from '../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../common/components/ViewPage/DisplayDescriptionValue';
import { defaultTags } from '../../common/components/Form/TagInput';
import ClientTag from '../../common/components/Tags/ClientTag';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';
import { getStatusDisplayNameForWOM, womStatusToAllStatusMap } from './businessLogic/serviceRequestStatusOptions';
import {
  downtime,
  metadataChecklist,
  metadataClosedAt,
  metadataCompletedAt,
  metadataDescription,
  metadataDueDate,
  metadataPriority,
  metadataShortId,
  metadataSize,
  metadataTitle,
  symptoms,
  closed,
  metadatascheduledDate,
  metadatarecurrenceInterval,
  metadatarecurrenceOption,
  metadatafailureDate,
  metadatafollowUpDate,
  metadataclientFollowUpDate,
  dispatchStatus,
  specMetadataCategoryId,
  equipmentMetadataSubclasses,
  specMetadataManufacturer,
  specMetadataModel,
  metadataEquipmentId,
  equipmentMetadataShortId,
  metadataEquipmentName,
  equipmentMetadataExternalId,
  metadataAasContractId,
  locationMetadataShortId,
  metadataLocationId,
  metadataLocationAddressLine1,
  metadataLocationAddressCity,
  metadataLocationAddressState,
  metadataLocationAddressZip,
  metadataLocationDisplayName,
  metadataProviderId,
  metadataAssignedInternalUserId,
  metadataMainContactUserId,
  metadataMainContactEmail,
  metadataProviderRefId,
  metadataServiceType,
  metadataSeverity,
  metadataPotentiallyChronic,
  metadataCancelationRequested,
  equipmentMetadataHasChronicIssues,
  metadataAdditionalPmCompleted,
  metadataCheckedInDuration,
  metadataBillable,
  metadataExternalWorkOrderNo,
  metadataIgnoreUntil,
  metadataPoAmount,
  invoiceMetadataInvoiceTotal,
  invoiceMetadataInvoiceStatus,
  metadataVariance,
  metadataNonCoverageInvoiceNumber,
  metadataNonCoverageInvoiceTotal,
  metadataOrganizationId,
  metadataMainContactPhoneNumberWithCountryCode,
  organizationMetadataClientOrganizationType,
  organizationMetadataIndustry,
  metadataRequestOrigination,
  metadataDispatchedToProviderAt,
  notes,
  closingNote,
  repairDescription,
  sublocationIds,
  approvals,
  serviceRequestId,
  specMetadataManufacturerEOL,
  specMetadataVendorLimitedSupportDate,
  equipmentMetadataCreatedAt,
  equipmentCoverageMetadataStatus,
  noteAttachment,
  deactivatedEquipment,
  metadataCompletedBy,
  metadataClosedBy,
  noteFromExternalUser,
  metadataNotOrganizationId,
} from './ServiceRequestColumnDefinitions';

export type ServiceRequestColumnsArgs = {
  serviceRequestSelectEnum: EnumObjectType<string>;
  userCan: AuthContextValue['userCan'];
  isAasUser: AuthContextValue['isAasUser'];
  auth: AuthorizationData | null | undefined;
  clientOrganizationType: ClientOrganizationEnumType | null;
  determineFeatureFlagVisibility: AuthContextValue['determineFeatureFlagVisibility'];
};
export function getServiceRequestColumns({
  serviceRequestSelectEnum,
  userCan,
  isAasUser,
  auth,
  clientOrganizationType,
  determineFeatureFlagVisibility,
}: ServiceRequestColumnsArgs): TableColumn<ServiceRequest>[] {
  const buildTagsForSr = (data) => {
    const severity = data?.original?.metadata?.severity || 1;
    const isCancelled = !!data?.original?.metadata?.cancelationRequested;

    const tags = (data?.value && Array.isArray(data?.value) && data?.value.map((text) => ({ text }))) || [];

    if (severity > 5) {
      tags.push({ text: 'Escalated', danger: true });
    }

    if (isCancelled) {
      tags.push({ text: 'Canceled' });
    }

    return tags;
  };

  return [
    metadataShortId,
    metadataTitle,
    metadataDescription,
    metadataSize,
    metadataPriority,
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility) ? [symptoms] : []),
    ...(userCan(internalUsers) || determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalChecklist) ? [metadataChecklist] : []),
    downtime,
    metadataCompletedAt,
    metadataCompletedBy,
    metadataDueDate,
    metadataClosedAt,
    metadataClosedBy,
    closed,
    metadatascheduledDate,
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalRecurrence)
      ? [metadatarecurrenceInterval, metadatarecurrenceOption]
      : []),
    metadatafailureDate,
    ...(clientOrganizationType !== ClientOrganizationEnumType.EquipsCoverage ? [metadataclientFollowUpDate] : []),
    ...(userCan(internalUsers) ? [metadatafollowUpDate] : []),
    {
      id: 'metadata.requestStatus',
      Header: <Translation>{(t) => <>{t('status')}</>}</Translation>,
      accessor: (data) => data.metadata?.requestStatus,
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        options: enumObjectToSelectOptions(serviceRequestSelectEnum),
        defaultValue: [],
        filterType: JsonParam,
        showOnMobile: true,
      },
      width: defaultColumnWidths.normalText,
      toText: (data) => {
        return getStatusDisplayNameForWOM(data?.value, womStatusToAllStatusMap, clientOrganizationType);
      },
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          formatter={(value) => {
            return getStatusDisplayNameForWOM(value, womStatusToAllStatusMap, clientOrganizationType);
          }}
          inputType={descriptionListInputTypes.select}
          options={enumObjectToSelectOptions(serviceRequestSelectEnum)}
          onSave={(value) =>
            patchServiceRequest({ serviceRequestId: data.row?.original?.metadata?.serviceRequestId, metadata: { requestStatus: value } })
          }
        />
      ),
    },
    dispatchStatus,
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility)
      ? [
          specMetadataCategoryId,
          equipmentMetadataSubclasses,
          specMetadataManufacturer,
          specMetadataModel,
          specMetadataManufacturerEOL,
          specMetadataVendorLimitedSupportDate,
          metadataEquipmentId,
          equipmentMetadataShortId,
          metadataEquipmentName,
          equipmentMetadataExternalId,
        ]
      : []),
    ...(userCan(internalUsers) || isAasUser ? [metadataAasContractId] : []),
    locationMetadataShortId,
    metadataLocationId,
    metadataLocationAddressLine1,
    metadataLocationAddressCity,
    metadataLocationAddressState,
    metadataLocationAddressZip,
    metadataLocationDisplayName,
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility) ? [metadataProviderId] : []),
    metadataAssignedInternalUserId,
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrFieldsMainContactEmailPhone)
      ? [metadataMainContactUserId, metadataMainContactPhoneNumberWithCountryCode, metadataMainContactEmail]
      : []),
    metadataProviderRefId,
    metadataServiceType,
    metadataSeverity,
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility)
      ? [metadataPotentiallyChronic, equipmentMetadataHasChronicIssues]
      : []),
    metadataAdditionalPmCompleted,
    metadataCheckedInDuration,
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalBillable) ? [metadataBillable] : []),
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalExternalWorkerOrderNumber) ? [metadataExternalWorkOrderNo] : []),
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalPoAmount) ? [metadataPoAmount] : []),
    ...(!userCan(customers)
      ? [
          {
            id: 'referredByOrg',
            Header: <Translation>{(t) => <>{t('referredByMyOrganization')}</>}</Translation>,
            filterOptions: {
              type: TableFilterTypes.select,
              options: [
                { label: 'Any', value: '' },
                { label: 'Yes', value: 'true' + auth?.organizationId },
                { label: 'No', value: 'false' + auth?.organizationId },
              ],
            },
          },
        ]
      : []),
    ...(userCan(internalUsers)
      ? [
          {
            id: 'invoiceMetadata.shortId',
            Header: <Translation>{(t) => <>{t('invoiceNo')}</>}</Translation>,
            accessor: (data) => data.invoiceMetadata?.shortId,
            disableSortBy: true,
            Cell: (data) => {
              return userCan(internalUsers) ? (
                <div className="flex justify-between">
                  <Link to={`${Urls.INVOICES}/${data?.row?.original?.invoiceMetadata?.invoiceId}`}>{data.value}</Link>
                </div>
              ) : (
                <>{data.value}</>
              );
            },
          },
          invoiceMetadataInvoiceTotal,
          invoiceMetadataInvoiceStatus,
        ]
      : []),
    ...(userCan(internalUsers) ||
    (clientOrganizationType === ClientOrganizationEnumType.EquipsWorkOrderManagement &&
      determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.AddVarianceField))
      ? [metadataVariance]
      : []),
    ...(userCan(internalUsers) || clientOrganizationType !== ClientOrganizationEnumType.EquipsCoverage
      ? [metadataNonCoverageInvoiceNumber, metadataNonCoverageInvoiceTotal]
      : []),
    ...(userCan([...internalUsers, ...providers]) ? [metadataOrganizationId] : []),
    ...(userCan(internalUsers)
      ? [
          organizationMetadataClientOrganizationType,
          organizationMetadataIndustry,
          metadataRequestOrigination,
          {
            id: 'metadata.tags',
            Header: <Translation>{(t) => <>{t('tags')}</>}</Translation>,
            accessor: (data) => data.metadata?.tags,
            disableSortBy: true,
            width: defaultColumnWidths.largeText,
            filterOptions: {
              type: TableFilterTypes.tags,
            },
            toText: (data) =>
              buildTagsForSr(data)
                ?.map((t) => t.text)
                .join(',') || '',
            Cell: (data) => {
              return (
                <TableCellInlineEditable
                  {...data}
                  formatter={() => {
                    const tags = buildTagsForSr(data);

                    return (
                      <div className="inline-block overflow-x-auto">
                        {tags.map(({ text, ...restOfProps }, i) => (
                          <span key={i} className="">
                            <Pill {...restOfProps} className="text-xs">
                              {text}
                            </Pill>
                          </span>
                        )) || ''}
                      </div>
                    );
                  }}
                  inputType={descriptionListInputTypes.multiSelectWithOptions}
                  options={defaultTags}
                  onSave={(value) =>
                    patchServiceRequest({ serviceRequestId: data.row?.original?.metadata?.serviceRequestId, metadata: { tags: value } })
                  }
                />
              );
            },
          },
          metadataDispatchedToProviderAt,
        ]
      : []),
    {
      id: 'tagMetadata.tagId',
      Header: <Translation>{(t) => <>{t(userCan(internalUsers) ? 'clientTags' : 'tags')}</>}</Translation>,
      accessor: (data) => data.metadata?.clientTags,
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      filterOptions: {
        type: TableFilterTypes.clientTagsMultiSelect,
        prependedOptions: [{ label: 'None', value: 'none' }],
        defaultValue: [],
        filterType: JsonParam,
        entityLabel: EntityLabelEnum.ServiceRequest,
      },
      toText: (data) => data?.value?.map((t) => t.name)?.join(',') || '',
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value?.map((item) => ({ value: item?.tagId, label: item?.name, style: item?.style }))}
            formatter={(value) => {
              return (
                <div className="inline-block overflow-x-auto">
                  {value?.map(({ value, label, style }) => <ClientTag key={value} label={label} style={style} value={value} />) || ''}
                </div>
              );
            }}
            inputType={descriptionListInputTypes.clientTagsMultiSelect}
            entityLabel={EntityLabelEnum.ServiceRequest}
            label=""
            onSave={(selections) => {
              const clientTags = selections ? selections?.map((selection) => ({ tagId: selection?.value })) : [];

              patchServiceRequest({
                serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
                metadata: { clientTags },
              });
            }}
          />
        );
      },
    },
    notes,
    closingNote,
    ...(userCan(internalUsers) ? [noteFromExternalUser] : []),
    ...(userCan(internalUsers) || clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage ? [repairDescription] : []),
    sublocationIds,
    approvals,
    noteAttachment,
    serviceRequestId,
    equipmentMetadataCreatedAt,
    equipmentCoverageMetadataStatus,
    deactivatedEquipment,
    metadataIgnoreUntil,
    metadataCancelationRequested,
    metadataNotOrganizationId,
  ];
}

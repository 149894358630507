import { lazy } from 'react';

export const CAPTURE_URLS = {
  ROOT: '/capture',
  CAPTURE_VERIFY: '/capture/verify',
  CAPTURE_ITEM_DETAILS: '/details/:id',
  CAPTURE_PRINT: '/capture/print',
  CAPTURE_SCAN: '/capture/scan',
};

export const captureRoutes = [
  {
    path: CAPTURE_URLS.ROOT,
    component: lazy(() => import('../InventoryCapture')),
  },
  {
    path: CAPTURE_URLS.CAPTURE_VERIFY,
    component: lazy(() => import('../CaptureApp')),
    routes: [
      {
        path: `${CAPTURE_URLS.CAPTURE_VERIFY}${CAPTURE_URLS.CAPTURE_ITEM_DETAILS}`,
        component: lazy(() => import('../screens/ViewCapture')),
      },
    ],
  },
  {
    path: CAPTURE_URLS.CAPTURE_PRINT,
    component: lazy(() => import('../screens/PrintQRCodesScreen')),
  },
  {
    path: CAPTURE_URLS.CAPTURE_SCAN,
    component: lazy(() => import('../screens/CaptureScreen')),
  },
  {
    path: `${CAPTURE_URLS.CAPTURE_SCAN}/:captureId`,
    component: lazy(() => import('../screens/CaptureScreen')),
  },
];

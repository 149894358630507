import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { Route, useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import NavBar from './common/components/Menu/NavBar';
import MainRouter from './routes/MainRouter';
import MainAlert from './common/components/Alerts/MainAlert';
import MainErrorBoundary from './common/components/ErrorBoundary/MainErrorBoundary';
import useLogin from './common/hooks/useAuthAndNetwork';
import MainLoader from './common/components/Loaders/Main';
import CachedAppDetector from './common/components/CachedAppDetector';
import { AuthorizationData, AuthProvider } from './common/auth/AuthContext';
import ModalProvider from './common/providers/ModalProvider';
import config from './config';
import { theme } from './styles/materialTheme';
import NavDataProvider from './common/providers/NavDataProvider';
import { FliptProvider } from './common/providers/flipt/provider';

const ErrorScreen = lazy(() => import('./screens/error-screen/ErrorScreen'));

export default function App({ fakeAuth }: { fakeAuth?: AuthorizationData }) {
  const { authorizationData, isAuthenticating, AuthWarningModals } = useLogin(fakeAuth);
  let location = useLocation();
  const serverError = authorizationData?.serverError;

  const hideIntercomWidget = useRef(true);

  useEffect(() => {
    if (config?.isProd) console.log('EQUIPS', new Date(__buildDate__).toDateString(), __buildVersion__);
    if (config?.isProd) {
      LogRocket.init('9srnkm/equips-webapp', { release: __buildVersion__ });
      LogRocket.identify(authorizationData?.userId || '', {
        email: authorizationData?.email || '',
        name: authorizationData?.fullName || '',
        role: authorizationData?.authorizationRole || '',
      });
      setupLogRocketReact(LogRocket);
    }
  }, [authorizationData]);

  useEffect(() => {
    if (window.Intercom) {
      // /qr/scan/:captureId
      if (location.pathname.match(/^\/capture\/scan\/([^/]+)$/)) {
        window.Intercom('update', { hide_default_launcher: true });
        hideIntercomWidget.current = true;
      }
      // Send location-changed event to intercom with the location as metadata
      window.Intercom('trackEvent', 'location-changed', location);
      console.debug('Intercom location-changed event sent', location);
    }

    return () => {
      if (hideIntercomWidget.current) {
        window.Intercom('update', { hide_default_launcher: false });
        hideIntercomWidget.current = false;
      }
    };
  }, [location]);

  const namespace = config?.isProd ? 'prod' : 'dev';
  const fliptUrl = 'https://flipt.equips.com';

  return (
    <Suspense fallback={<MainLoader />}>
      {serverError ? (
        <ErrorScreen title="Sorry, there seems to be an issue right now. Our engineering team has been notified." />
      ) : isAuthenticating ? (
        <MainLoader />
      ) : (
        <MainAlert>
          <AuthProvider auth={authorizationData}>
            <FliptProvider namespace={namespace} options={{ url: fliptUrl }}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ModalProvider>
                        <NavDataProvider userId={authorizationData?.userId}>
                          <NavBar>
                            <Suspense fallback={<div className="min-h-screen" />}>
                              <MainErrorBoundary>
                                <main>
                                  <AuthWarningModals />
                                  <MainRouter />
                                  <CachedAppDetector />
                                </main>
                              </MainErrorBoundary>
                            </Suspense>
                          </NavBar>
                        </NavDataProvider>
                      </ModalProvider>
                    </LocalizationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </QueryParamProvider>
            </FliptProvider>
          </AuthProvider>
        </MainAlert>
      )}
    </Suspense>
  );
}
